<template lang="pug">
  .matchings-table
    .matchings-table-content
      table.container-fluid
        thead
          tr
            th.sync.sortable(@click="handleSorting('car_classes.sync')")
              span {{ $t("car_class_matching.sync") }}
              FaIcon.icon(:icon="sortingIcon('car_classes.sync')")
            th.item-name.sortable(@click="handleSorting('shops.name')")
              span {{ $t("shop_matching.shop_name") }}
              FaIcon.icon(:icon="sortingIcon('shops.name')")
            th.dp.sortable(v-if="currentOrganization.dp" @click="handleSorting('car_classes.dp')")
              span {{ $t("car_class_matching.dp") }}
              FaIcon.icon(:icon="sortingIcon('car_classes.dp')")
            th.inbound.sortable(v-if="currentOrganization.inbound" @click="handleSorting('car_classes.inbound')")
              span {{ $t("car_class_matching.inbound") }}
              FaIcon.icon(:icon="sortingIcon('car_classes.inbound')")
            th.item-name.sortable(@click="handleSorting('name')")
              span {{ $t("shops_sources_car_matching.sources_car") }}
              FaIcon.icon(:icon="sortingIcon('name')")
            th.item-name.dropdown
              span {{ $t("shops_car_class_matching.car_class") }}
            th.select-quantity
        tbody(v-if="items")
          template(v-for="itemsStore in [itemsAdded, items]")
            ShopItem(
              v-for="(item, index) in itemsStore"
              :key="item.id || item._addedAt"
              :index="index"
              :item="item"
              :shops="shops"
              :sources-car-classes-by-ota-id="sourcesCarClassesByOtaId"
              :selected-sources-car-classes="selectedSourcesCarClasses"
              :sources-cars="sourcesCars"
              :selected-sources-cars="selectedSourcesCars"
              :sources-car-classes-loading="sourcesCarClassesLoading"
              :sources-cars-loading="sourcesCarsLoading"
              :invalid-item-ids="invalidItemIds"
              :add-selected-sources-car="addSelectedSourcesCar"
              :add-selected-sources-car-class="addSelectedSourcesCarClass"
              :remove-selected-sources-car="removeSelectedSourcesCar"
              :remove-selected-sources-car-class="removeSelectedSourcesCarClass"
              @delete-item="deleteItem(item, index)"
              @update-item="updateItem($event, index)"
            )
</template>

<script>
  // mixins
  import withSorting from "@/mixins/withSorting"
  import withBrowserDetect from "@/mixins/withBrowserDetect"
  import { mapGetters } from "vuex"

  import { isEmpty } from "lodash-es"

  export default {
    mixins: [withSorting, withBrowserDetect],

    props: {
      items: Array,
      shops: Array,
      sortingData: Object,
      itemsAdded: Array,
      itemsDeleted: Array,
      itemsUpdated: Array,
      sourcesCarClassesByOtaId: Object,
      selectedSourcesCarClasses: Object,
      selectedSourcesCars: Object,
      updateExistingItem: Function,
      updateAddedItem: Function,
      deleteExistingItem: Function,
      deleteAddedItem: Function,
      addSelectedSourcesCar: Function,
      addSelectedSourcesCarClass: Function,
      removeSelectedSourcesCar: Function,
      removeSelectedSourcesCarClass: Function,
      invalidItemIds: {
        type: Array,
        default: () => new Array()
      },
      sourcesCarClassesLoading: Boolean,
      sourcesCars: {
        type: Array,
        default: () => new Array()
      },
      sourcesCarsLoading: Boolean,
      isShopsWithAlertCars: Boolean
    },

    components: {
      ShopItem: () => import("./ShopsSourcesCarItem")
    },

    computed: {
      ...mapGetters(["currentOrganization"])
    },

    methods: {
      isAddedItem(item) {
        return !isEmpty(item._addedAt)
      },

      deleteItem(item, index) {
        const deleteAction = this.isAddedItem(item) ? this.deleteAddedItem : this.deleteExistingItem

        deleteAction(index)
      },

      updateItem(item, index) {
        const updateAction = this.isAddedItem(item) ? this.updateAddedItem : this.updateExistingItem

        updateAction({ item, index })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/matchings.sass"

  .matchings-table
    +matchings-table-header

    th
      &.select-quantity
        z-index: 11
        width: 5%
        right: 0
      &.item-name
        &.dropdown
          padding: 0 17px
</style>
